<template>
  <v-container
    class="products-list-page"
    fluid>
    <v-row
      justify="space-between"
      class="head-search ma-0">
      <v-spacer />
      <v-col
        cols="auto"
        class="d-flex align-center date-picker-col">
        <date-range-picker v-model="dateRange" />
      </v-col>
    </v-row>
    <v-row class="products-row">
      <v-col cols="12">
        <v-row>
          <v-col>
            <h1 class="page-title">
              Today Report
            </h1>
          </v-col>
          <v-spacer />
          <v-col class="d-flex justify-end">
            <v-btn
              icon
              class="show-data-btn"
              @click="show.today = !show.today">
              <v-icon v-if="show.today">
                mdi-menu-up
              </v-icon>
              <v-icon v-else>
                mdi-menu-down
              </v-icon>
            </v-btn>
          </v-col>
          <v-divider vertical />
        </v-row>
        <today-report
          v-show="show.today && loaded"
          :stats="statsPerDate"
          :today-stats="todayStats" />
        <v-row
          v-if="!loaded"
          justify="center">
          <div class="d-flex justify-center">
            <v-progress-circular
              indeterminate
              color="black" />
            <h3 class="ml-4">
              กรุณารอสักครู่...
            </h3>
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="products-row">
      <v-col cols="12">
        <v-row>
          <v-col>
            <h1 class="page-title">
              POS Report
            </h1>
          </v-col>
          <v-spacer />
          <v-col class="d-flex justify-end">
            <v-btn
              icon
              class="show-data-btn"
              @click="show.pos = !show.pos">
              <v-icon v-if="show.pos">
                mdi-menu-up
              </v-icon>
              <v-icon v-else>
                mdi-menu-down
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <pos-report
          v-show="show.pos && loaded"
          :each-branch="statsForEachBranch.pos"
          :stats="statsPerDate.pos"
          :amount-order-per-date="amountOrderPerDate.pos" />
        <v-row
          v-if="!loaded"
          justify="center">
          <div class="d-flex justify-center">
            <v-progress-circular
              indeterminate
              color="black" />
            <h3 class="ml-4">
              กรุณารอสักครู่...
            </h3>
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="products-row">
      <v-col cols="12">
        <v-row>
          <v-col>
            <h1 class="page-title">
              Manual Report
            </h1>
          </v-col>
          <v-spacer />
          <v-col class="d-flex justify-end">
            <v-btn
              icon
              class="show-data-btn"
              @click="show.manual = !show.manual">
              <v-icon v-if="show.manual">
                mdi-menu-up
              </v-icon>
              <v-icon v-else>
                mdi-menu-down
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <manual-report
          v-show="show.manual && loaded"
          :today-stats="todayStats.manual"
          :each-branch="statsForEachBranch.manual"
          :stats="statsPerDate.manual"
          :amount-order-per-date="amountOrderPerDate.manual"
          :order-amount="orderAmount.manual.amount" />
        <v-row
          v-if="!loaded"
          justify="center">
          <div class="d-flex justify-center">
            <v-progress-circular
              indeterminate
              color="black" />
            <h3 class="ml-4">
              กรุณารอสักครู่...
            </h3>
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="products-row">
      <v-col cols="12">
        <v-row>
          <v-col>
            <h1 class="page-title">
              Online Report
            </h1>
          </v-col>
          <v-spacer />
          <v-col class="d-flex justify-end">
            <v-btn
              icon
              class="show-data-btn"
              @click="show.online = !show.online">
              <v-icon v-if="show.online">
                mdi-menu-up
              </v-icon>
              <v-icon v-else>
                mdi-menu-down
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <online-report
          v-show="show.online && loaded"
          :date-range="dateRange"
          :stats="statsPerDate.web"
          :today-stats="todayStats.web"
          :each-branch="statsForEachBranch.web"
          :amount-order-per-date="amountOrderPerDate.web" />
        <v-row
          v-if="!loaded"
          justify="center">
          <div class="d-flex justify-center">
            <v-progress-circular
              indeterminate
              color="black" />
            <h3 class="ml-4">
              กรุณารอสักครู่...
            </h3>
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="products-row">
      <v-col cols="12">
        <v-row>
          <v-col>
            <h1 class="page-title">
              Click and Collect Report
            </h1>
          </v-col>
          <v-spacer />
          <v-col class="d-flex justify-end">
            <v-btn
              icon
              class="show-data-btn"
              @click="show.clickAndCollect = !show.clickAndCollect">
              <v-icon v-if="show.clickAndCollect">
                mdi-menu-up
              </v-icon>
              <v-icon v-else>
                mdi-menu-down
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <click-and-collect-report
          v-show="show.clickAndCollect && loaded"
          :date-range="dateRange"
          :stats="statsPerDate.clickAndCollect"
          :today-stats="todayStats.clickAndCollect"
          :amount-order-per-date="amountOrderPerDate.clickAndCollect"
          :order-amount="orderAmount.clickAndCollect" />
        <v-row
          v-if="!loaded"
          justify="center">
          <div class="d-flex justify-center">
            <v-progress-circular
              indeterminate
              color="black" />
            <h3 class="ml-4">
              กรุณารอสักครู่...
            </h3>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ReportProvider from '@/resources/ReportProvider'
import { mapActions } from 'vuex'
import DateRangePicker from '../components/DatesPicker.vue'
import TodayReport from '../components/TodayReport.vue'
import PosReport from '../components/PosReport.vue'
import OnlineReport from '../components/OnlineReport.vue'
import ManualReport from '../components/ManualReport.vue'
import ClickAndCollectReport from '../components/ClickAndCollectReport.vue'

const ReportService = new ReportProvider()

export default {
  components: {
    DateRangePicker,
    TodayReport,
    PosReport,
    OnlineReport,
    ManualReport,
    ClickAndCollectReport
  },
  data () {
    return {
      dateRange: [],
      loaded: true,
      show: {
        today: true,
        pos: true,
        online: true,
        manual: true,
        clickAndCollect: true
      },
      statsPerDate: {
        web: [],
        pos: [],
        manual: [],
        clickAndCollect: []
      },
      todayStats: {
        web: {
          totalSpent: 0,
          totalOrder: 0,
          averageSpentPerOrder: 0
        },
        pos: {
          totalSpent: 0,
          totalOrder: 0,
          averageSpentPerOrder: 0
        },
        manual: {
          totalSpent: 0,
          totalOrder: 0,
          averageSpentPerOrder: 0
        },
        clickAndCollect: {
          totalSpent: 0,
          totalOrder: 0,
          averageSpentPerOrder: 0
        }
      },
      amountOrderPerDate: {
        web: [],
        pos: [],
        manual: [],
        clickAndCollect: []
      },
      statsForEachBranch: {
        pos: [],
        web: [],
        manual: [],
        clickAndCollect: []
      },
      orderAmount: {
        pos: {
          amount: 0
        },
        web: {
          amount: 0
        },
        manual: {
          amount: 0
        },
        clickAndCollect: {
          amount: 0,
          tryNumber: 0
        }
      }
    }
  },
  watch: {
    dateRange: {
      handler () {
        this.getReportsPerDate()
      },
      deep: true
    }
  },
  mounted () {
    this.initDefaultDate()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    resetData () {
      this.statsPerDate = {
        web: [],
        pos: [],
        manual: [],
        clickAndCollect: []
      }
      this.todayStats = {
        web: {
          totalSpent: 0,
          totalOrder: 0,
          averageSpentPerOrder: 0
        },
        pos: {
          totalSpent: 0,
          totalOrder: 0,
          averageSpentPerOrder: 0
        },
        manual: {
          totalSpent: 0,
          totalOrder: 0,
          averageSpentPerOrder: 0
        },
        clickAndCollect: {
          totalSpent: 0,
          totalOrder: 0,
          averageSpentPerOrder: 0
        }
      }
      this.amountOrderPerDate = {
        web: [],
        pos: [],
        manual: [],
        clickAndCollect: []
      }
      this.statsForEachBranch = {
        pos: [],
        web: [],
        manual: [],
        clickAndCollect: []
      }
      this.orderAmount = {
        pos: {
          amount: 0
        },
        web: {
          amount: 0
        },
        manual: {
          amount: 0
        },
        clickAndCollect: {
          amount: 0,
          tryNumber: 0
        }
      }
    },
    initDefaultDate () {
      const startDate = this.$dayjs().subtract(6, 'day').format('YYYY-MM-DD')
      const endDate = this.$dayjs().format('YYYY-MM-DD')
      this.dateRange = [startDate, endDate]
    },
    async getReportsPerDate () {
      try {
        this.loaded = false
        this.resetData()
        const tmpDate = [
          `${this.dateRange[0]} 17:00:00`,
          `${this.dateRange[1]} 16:59:59`
        ]
        const { data: reports } = await ReportService.getReportsPerDate(tmpDate)

        this.todayStats = reports.todayStats
        this.statsPerDate = reports.statsPerDate
        this.amountOrderPerDate = reports.amountOrderPerDate
        this.statsForEachBranch = reports.statsForEachBranch
        this.orderAmount = reports.orderAmount
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
        console.error(`GET ALL REPORTS ERROR (${error.code})`)
      } finally {
        this.loaded = true
      }
    }
  }
}
</script>

<style scoped>

.page-title {
  text-decoration: underline;
}
</style>
