var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('div',{staticClass:"table-wrap ma-2"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.contents,"hide-default-footer":"","dense":"","items-per-page":20},scopedSlots:_vm._u([{key:"item.label",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.label.toUpperCase())+" ")]}},{key:"item.visitor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.visitor)+" ")]}},{key:"item.totalOrder",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.showFullPriceFormat(item.totalOrder, 0))+" ")]}},{key:"item.totalSpent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.showFullPriceFormat(item.totalSpent, 0))+" ")]}},{key:"item.conversionRate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.conversionRate.toFixed(2)) + "%"))+" ")]}},{key:"item.averageSpentPerOrder",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.showFullPriceFormat(item.averageSpentPerOrder, 0))+" ")]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',[_vm._v(_vm._s(_vm.totalInReportTable.label))]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.showFullPriceFormat(_vm.totalInReportTable.visitor, 0))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.showFullPriceFormat(_vm.totalInReportTable.totalOrder, 0))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.showFullPriceFormat(_vm.totalInReportTable.totalSpent, 0))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(((_vm.showFullPriceFormat(_vm.totalInReportTable.conversionRate, 2)) + "%"))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.showFullPriceFormat(_vm.totalInReportTable.averageSpentPerOrder, 0))+" ")])])]},proxy:true}],null,true)})],1)]),_c('v-row',[_vm._l((Object.keys(_vm.charts)),function(field,index){return _c('v-col',{key:index,attrs:{"cols":"8","offset":"2"}},[_c('line-chart',{attrs:{"chart-data":_vm.charts[field].dataCollection,"options":_vm.charts[field].chartOptions}})],1)}),_c('v-col',{attrs:{"cols":"8","offset":"2"}},[_c('bar-chart',{attrs:{"chart-data":_vm.amountOrderOfSpentRange.dataCollection,"options":_vm.amountOrderOfSpentRange.chartOptions}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }