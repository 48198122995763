<template>
  <v-container>
    <v-row justify="start">
      <h3 class="ml-16">
        Today
      </h3>
    </v-row>

    <v-row
      class="number-row"
      justify="center">
      <v-col cols="4">
        <v-card
          class="number-box"
          elevation="2">
          <h3 class="title">
            ยอดขายวันนี้
          </h3>
          <h2 class="content">
            {{ todayStat.totalSpent | showNumberFormat() }}
          </h2>
          <h5 class="extend">
            {{ todayStat.totalOrder }} orders
          </h5>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="8">
        <div class="chart-box">
          <line-chart
            :chart-data="dataCollection"
            :options="chartOptions" />
        </div>
      </v-col>
    </v-row>
    <web-report
      :stats="stats"
      :date-range="dateRange"
      :today-stats="todayStats"
      :each-branch="eachBranch"
      :amount-order-per-date="amountOrderPerDate" />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import LineChart from './charts/LineChart'
import WebReport from './WebReport.vue'

export default {
  components: {
    LineChart,
    WebReport
  },
  props: {
    dateRange: {
      type: Array,
      required: true
    },
    stats: {
      type: Array,
      required: true
    },
    todayStats: {
      type: Object,
      required: true
    },
    amountOrderPerDate: {
      type: Array,
      required: true
    },
    eachBranch: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      loaded: true,
      todayStat: {
        totalSpent: 0,
        totalOrder: 0
      },
      dataCollection: {
        labels: [],
        datasets: []
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: 'ยอดขายออนไลน์',
          position: 'top'
        }
      }
    }
  },
  watch: {
    todayStats: {
      handler () {
        this.initCharts()
      },
      deep: true
    },
    stats: {
      handler () {
        this.initCharts()
      },
      deep: true
    }
  },
  mounted () {
    this.initCharts()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    async initTodayStats () {
      this.todayStat.totalSpent = this.todayStats?.totalSpent || 0
      this.todayStat.totalOrder = this.todayStats?.totalOrder || 0
    },
    async initCharts () {
      try {
        this.loaded = false
        this.initLabel()
        this.initTodayStats()
        this.initDataForChart(this.stats)
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
        console.error('GET ONLINE REPORTS ERROR')
      } finally {
        this.loaded = true
      }
    },
    initLabel () {
      this.dataCollection.labels = this.stats.map((stat) => stat.reportDate)
    },
    initDataForChart (reports) {
      this.dataCollection.datasets = []
      this.dataCollection.datasets.push({
          label: 'WEB',
          fill: false,
          borderColor: 'green',
          backgroundColor: 'green',
          data: reports.map((report) => report.totalSpentOnDate)
      })
    }
  }
}
</script>

<style scoped>
.page-title {
  text-decoration: underline;
}

.number-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
  border: 2px solid black;
  position: relative;
}

.number-box .title {
  position: absolute;
  top: -17px;
  left: 5px;
  padding: 0 5px;
  background-color: #fff;
}

.number-box .extend {
  position: absolute;
  bottom: 0px;
  right: 2px;
}

.number-row {
  margin: 16px auto;
}

</style>
