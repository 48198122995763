<template>
  <v-container>
    <v-row
      class="number-row"
      justify="center">
      <v-col cols="4">
        <v-card
          class="number-box"
          elevation="2">
          <h3 class="title">
            ยอดขายวันนี้
          </h3>
          <h2 class="content">
            {{ todayStat.totalSpent | showNumberFormat }}
          </h2>
          <h5 class="extend">
            {{ todayStat.totalOrder }} orders
          </h5>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      class="stats-row"
      justify="center">
      <v-col class="stats-col d-flex justify-center">
        <v-card
          class="stats-box"
          elevation="2">
          <h2 class="content">
            {{ orderAmount | showNumberFormat }}
          </h2>
          <h3 class="title">
            Checkout
          </h3>
        </v-card>
        <div class="stats-connector">
          <h1 class="arrow">
            -->
          </h1>
          <h4 class="text">
            {{ (totalInReportTable.totalOrder / orderAmount) * 100 | showNumberFormat }}%
          </h4>
        </div>
        <v-card
          class="stats-box"
          elevation="2">
          <h2 class="content">
            {{ totalInReportTable.totalOrder | showNumberFormat }}
          </h2>
          <h3 class="title">
            Purchase
          </h3>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      justify="center"
      class="stats-table-row my-4">
      <v-col cols="10">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">
                  Checkout
                </th>
                <th class="text-center">
                  Purchase
                </th>
                <th class="text-center">
                  ยอดขาย
                </th>
                <th class="text-center">
                  Conversion rate
                </th>
                <th class="text-center">
                  ยอดเฉลี่ยต่อออร์เดอร์
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">
                  {{ orderAmount | showNumberFormat }}
                </td>
                <td class="text-center">
                  {{ totalInReportTable.totalOrder | showNumberFormat }}
                </td>
                <td class="text-center">
                  {{ totalInReportTable.totalSpent | showNumberFormat }}
                </td>
                <td class="text-center">
                  {{ totalInReportTable.conversionRate.toFixed(2) || 0 }} %
                </td>
                <td class="text-center">
                  {{ totalInReportTable.averageSpentPerOrder | showNumberFormat }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(field, index) in Object.keys(charts)"
        :key="index"
        cols="8"
        offset="2">
        <line-chart
          :chart-data="charts[field].dataCollection"
          :options="charts[field].chartOptions" />
      </v-col>
      <v-col
        cols="8"
        offset="2">
        <bar-chart
          :chart-data="amountOrderOfSpentRange.dataCollection"
          :options="amountOrderOfSpentRange.chartOptions" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ReportProvider from '@/resources/ReportProvider'
import { mapActions } from 'vuex'
import LineChart from './charts/LineChart'
import BarChart from './charts/BarChart'

const ReportService = new ReportProvider()
export default {
  components: {
    LineChart,
    BarChart
  },
  props: {
    stats: {
      type: Array,
      required: true
    },
    todayStats: {
      type: Object,
      required: true
    },
    eachBranch: {
      type: Array,
      required: true
    },
    amountOrderPerDate: {
      type: Array,
      required: true
    },
    orderAmount: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      loaded: true,
      amountOrderOfSpentRange: {
        dataCollection: null,
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: 'Net Order Value',
            position: 'top'
          }
        }
      },
      charts: {
        orderNumber: {
          dataCollection: null,
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: true,
              text: 'จำนวนออเดอร์',
              position: 'top'
            }
          }
        },
        spentNumber: {
          dataCollection: null,
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: true,
              text: 'ยอดขาย',
              position: 'top'
            }
          }
        },
        conversionRate: {
          dataCollection: null,
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: true,
              text: 'Conversion Rate',
              position: 'top'
            }
          }
        }
      },
      headers: [
        {
          text: '',
          sortable: false,
          value: 'label'
        },
        {
          text: 'จำนวนออเดอร์',
          sortable: true,
          align: 'center',
          value: 'totalOrder'
        },
        {
          text: 'ยอดขาย',
          sortable: true,
          align: 'center',
          value: 'totalSpent'
        },
        {
          text: 'Conversion rate',
          sortable: true,
          align: 'center',
          value: 'conversionRate'
        },
        {
          text: 'ยอดเฉลี่ยต่อออเดอร์',
          sortable: true,
          align: 'center',
          value: 'averageSpentPerOrder'
        }
      ],
      contents: [],
      totalInReportTable: {
        label: 'TOTAL',
        totalOrder: 0,
        totalSpent: 0,
        conversionRate: 0,
        averageSpentPerOrder: 0
      },
      todayStat: {
        totalOrder: 0,
        totalSpent: 0
      }
    }
  },
  watch: {
    todayStats: {
      handler () {
        this.initTodayStats()
      },
      deep: true
    },
    eachBranch: {
      handler () {
        this.fillData()
        this.initLabel()
        this.getReports()
      }
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    async initTodayStats () {
      this.todayStat.totalSpent = this.todayStats?.totalSpent || 0
      this.todayStat.totalOrder = this.todayStats?.totalOrder || 0
    },
    async getReports () {
      try {
        this.loaded = false
        this.initEachDateChart()
        this.initReportTable()
        this.initAmountOrderOfSpentRange()
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
        console.error('GET MANUAL REPORTS ERROR')
      } finally {
        this.loaded = true
      }
    },
    async initEachDateChart () {
      Object.keys(this.charts).forEach((chart) => {
        this.charts[chart].dataCollection.datasets = this.prepareDataForDataSets(this.stats, chart)
      })
    },
    async getBranches () {
      let branches = []
      try {
        const { data } = await ReportService.getBranches()
        branches = data.map((branch) => (branch.branch))
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
        console.error('GET BRANCHES ERROR')
      }

      return branches
    },
    reportFinder (reports, chart) {
      const results = []
      reports.forEach((report) => {
        const temp = {
          orderNumber: 0,
          spentNumber: 0,
          conversionRate: 0
        }

        report.reports.forEach((data) => {
          temp[chart] += data[chart] || 0
        })

        results.push(temp[chart].toFixed(2))
      })

      return results
    },
    prepareDataForDataSets (reports, chart) {
      const result = [{
        label: 'Value',
        fill: false,
        borderColor: 'purple',
        backgroundColor: 'purple',
        data: this.reportFinder(reports, chart)
      }]

      return result
    },
    initReportTable () {
      const contents = []
      this.stats.forEach((report) => {
        if (report.reports.length) {
          const totalOrderNumber = report.reports.reduce((sum, r) => sum + r.orderNumber, 0)
          const totalSpentNumber = report.reports.reduce((sum, r) => sum + r.spentNumber, 0)

          contents.push({
            averageSpentPerOrder: totalSpentNumber / totalOrderNumber,
            totalOrder: totalOrderNumber,
            totalSpent: totalSpentNumber,
            label: 'REPORT TABLE',
            conversionRate: (totalOrderNumber * 100) / this.orderAmount || 0
          })
        }
      })

      this.totalInReportTable = this.calcTotalOrderStats(contents)
      // contents.push(this.totalInReportTable)
      this.contents = contents
    },
    async initAmountOrderOfSpentRange () {
      this.amountOrderOfSpentRange.dataCollection.labels = this.amountOrderPerDate.map((each) => each.label)
      this.amountOrderOfSpentRange.dataCollection.datasets = [{
        label: 'Bills',
        data: this.amountOrderPerDate.map((each) => each.amount),
        backgroundColor: '#446bc7',
        borderColor: '#446bc7'
      }]
    },
    calcTotalOrderStats (contents) {
      const totalOrderNumber = contents.reduce((sum, content) => sum + content.totalOrder, 0)
      const totalSpentNumber = contents.reduce((sum, content) => sum + content.totalSpent, 0)
      const totalConversionRate = (this.orderAmount !== 0) ? (totalOrderNumber * 100) / this.orderAmount : 0
      const totalAverageSpentPerOrder = totalSpentNumber / totalOrderNumber

      return {
        label: 'TOTAL',
        totalOrder: totalOrderNumber,
        totalSpent: totalSpentNumber,
        conversionRate: totalConversionRate,
        averageSpentPerOrder: totalAverageSpentPerOrder
      }
    },
    fillData () {
      Object.keys(this.charts).forEach((chart) => {
        this.charts[chart].dataCollection = {
          labels: [],
          datasets: []
        }
      })

      this.amountOrderOfSpentRange.dataCollection = {
        labels: [],
        datasets: []
      }
    },
    initLabel () {
      const label = this.stats.map((stat) => stat.reportDate)

      Object.keys(this.charts).forEach((chart) => {
        this.charts[chart].dataCollection.labels = label
      })
    },
    showFullPriceFormat (number = 0, fixed = 2) {
      return parseFloat(number).toFixed(fixed).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }
}
</script>

<style scoped>
.page-title {
  text-decoration: underline;
}

.table-wrap {
  width: 90%;
}

.number-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
  border: 2px solid black;
  position: relative;
}

.number-box .title {
  position: absolute;
  top: -17px;
  left: 5px;
  padding: 0 5px;
  background-color: #fff;
}

.number-box .extend {
  position: absolute;
  bottom: 0px;
  right: 2px;
}

.number-row {
  margin: 16px auto;
}

.stats-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 11%;
  border: 2px solid black;
  position: relative;
}

.stats-box .title {
  position: absolute;
  padding: 0 5px;
  bottom: -15px;
  font-size: 17px !important;
  background-color: #fff;
}

.stats-connector {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 11%;
  position: relative;
}

.stats-connector .text {
  position: absolute;
  bottom: 0px;
}
</style>
