<template>
  <v-container>
    <v-row>
      <v-col cols="7">
        <div class="chart-box">
          <line-chart
            :chart-data="dataCollection"
            :options="chartOptions" />
        </div>
      </v-col>

      <v-col class="d-flex justify-center">
        <v-divider vertical />
      </v-col>
      <v-col cols="4">
        <v-row justify="center">
          <h2>
            ยอดขายวันนี้
          </h2>
        </v-row>
        <v-row
          justify="center"
          class="number-row">
          <v-card
            class="number-box total"
            elevation="2">
            <h3 class="title">
              Total
            </h3>
            <h2 class="content">
              {{ todayStat.pos.totalSpent + todayStat.web.totalSpent + todayStat.manual.totalSpent | showNumberFormat() }}
            </h2>
            <h5 class="extend">
              {{ todayStat.pos.totalOrder + todayStat.web.totalOrder + todayStat.manual.totalOrder }} orders
            </h5>
          </v-card>
        </v-row>
        <v-row
          justify="center"
          class="number-row">
          <v-card
            class="number-box pos"
            elevation="2">
            <h3 class="title">
              POS
            </h3>
            <h2 class="content">
              {{ todayStat.pos.totalSpent | showNumberFormat() }}
            </h2>
            <h5 class="extend">
              {{ todayStat.pos.totalOrder }} orders
            </h5>
          </v-card>
        </v-row>
        <v-row
          justify="center"
          class="number-row">
          <v-card
            class="number-box manual"
            elevation="2">
            <h3 class="title">
              Manual
            </h3>
            <h2 class="content">
              {{ todayStat.manual.totalSpent | showNumberFormat() }}
            </h2>
            <h5 class="extend">
              {{ todayStat.manual.totalOrder }} orders
            </h5>
          </v-card>
        </v-row>
        <v-row
          justify="center"
          class="number-row">
          <v-card
            class="number-box web"
            elevation="2">
            <h3 class="title">
              WEB
            </h3>
            <h2 class="content">
              {{ todayStat.web.totalSpent | showNumberFormat() }}
            </h2>
            <h5 class="extend">
              {{ todayStat.web.totalOrder }} orders
            </h5>
          </v-card>
        </v-row>
        <v-row
          justify="center"
          class="number-row">
          <v-card
            class="number-box click"
            elevation="2">
            <h3 class="title">
              Click & Collect
            </h3>
            <h2 class="content">
              {{ todayStat.clickAndCollect.totalSpent | showNumberFormat() }}
            </h2>
            <h5 class="extend">
              {{ todayStat.clickAndCollect.totalOrder }} orders
            </h5>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import LineChart from './charts/LineChart'

export default {
  components: {
    LineChart
  },
  props: {
    stats: {
      type: Object,
      required: true
    },
    todayStats: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      trigger: 0,
      dataCollection: {
        datasets: [],
        labels: []
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: 'ยอดขาย',
          position: 'top'
        }
      },
      loaded: false,
      todayStat: {
        web: {
          totalSpent: 0,
          totalOrder: 0
        },
        pos: {
          totalSpent: 0,
          totalOrder: 0
        },
        manual: {
          totalSpent: 0,
          totalOrder: 0
        },
        clickAndCollect: {
          totalSpent: 0,
          totalOrder: 0
        }
      }
    }
  },
  watch: {
    stats: {
      handler () {
        this.getReports()
      },
      deep: true
    },
    todayStats: {
      handler () {
        this.initTodayStats()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    async getReports () {
      const channels = ['web', 'pos', 'manual', 'clickAndCollect']
      this.resetData()

      channels.forEach((channel) => {
        this.initDataForChart(this.stats[channel], channel)
      })
    },
    initChartLabel (reports) {
      this.dataCollection.labels = reports.map((report) => report.reportDate)
    },
    resetData () {
      this.dataCollection = {
        datasets: [],
        labels: []
      }

      this.todayStat = {
        web: {
          totalSpent: 0,
          totalOrder: 0
        },
        pos: {
          totalSpent: 0,
          totalOrder: 0
        },
        manual: {
          totalSpent: 0,
          totalOrder: 0
        },
        clickAndCollect: {
          totalSpent: 0,
          totalOrder: 0
        }
      }
    },
    initDataForChart (reports, channel) {
      this.initChartLabel(reports)
      let channelColor = ''
      if (channel === 'pos') channelColor = 'blue'
      else if (channel === 'web') channelColor = 'green'
      else if (channel === 'manual') channelColor = 'purple'
      else if (channel === 'clickAndCollect') channelColor = 'red'

      this.dataCollection.datasets.push({
          label: channel.toUpperCase(),
          fill: false,
          borderColor: channelColor,
          backgroundColor: channelColor,
          data: reports.map((report) => report.totalSpentOnDate)
      })
    },
    async initTodayStats () {
      this.todayStat = this.todayStats
    }
  }
}
</script>

<style scoped>

.number-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 70%;
  border: 2px solid black;
  position: relative;
}

.number-box .title {
  position: absolute;
  top: -17px;
  left: 5px;
  padding: 0 5px;
  background-color: #fff;
}

.number-box .extend {
  position: absolute;
  bottom: 0px;
  right: 2px;
}

.number-row {
  margin: 20px auto;
}

.number-box.pos {
  border: 2px solid blue;
}

.number-box.web {
  border: 2px solid green;
}

.number-box.manual {
  border: 2px solid purple;
}

.number-box.click {
  border: 2px solid red;
}
</style>
