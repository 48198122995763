<template>
  <div>
    <v-row justify="start">
      <h3 class="ml-16">
        Web
      </h3>
    </v-row>

    <v-row
      class="number-row"
      justify="center">
      <v-col cols="4">
        <v-card
          class="number-box"
          elevation="2">
          <h3 class="title">
            ยอดขายวันนี้
          </h3>
          <h2 class="content">
            {{ todayStat.totalSpent | showNumberFormat }}
          </h2>
          <h5 class="extend">
            {{ todayStat.totalOrder }} orders
          </h5>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      class="stats-row"
      justify="center">
      <v-col class="stats-col d-flex justify-center">
        <v-card
          class="stats-box"
          elevation="2">
          <h2 class="content">
            {{ contents.visitor | showNumberFormat }}
          </h2>
          <h3 class="title">
            Visits
          </h3>
        </v-card>
        <div class="stats-connector">
          <h1 class="arrow">
            -->
          </h1>
          <h4 class="text">
            {{ (contents.addToCart / contents.visitor)*100 | showNumberFormat }}%
          </h4>
        </div>
        <v-card
          class="stats-box"
          elevation="2">
          <h2 class="content">
            {{ contents.addToCart | showNumberFormat }}
          </h2>
          <h3 class="title">
            Cart
          </h3>
        </v-card>
        <div class="stats-connector">
          <h1 class="arrow">
            -->
          </h1>
          <h4 class="text">
            {{ (contents.checkout / contents.addToCart)*100 | showNumberFormat }}%
          </h4>
        </div>
        <v-card
          class="stats-box"
          elevation="2">
          <h2 class="content">
            {{ contents.checkout | showNumberFormat }}
          </h2>
          <h3 class="title">
            Checkout
          </h3>
        </v-card>
        <div class="stats-connector">
          <h1 class="arrow">
            -->
          </h1>
          <h4 class="text">
            {{ (contents.totalOrder / contents.checkout)*100 | showNumberFormat }}%
          </h4>
        </div>
        <v-card
          class="stats-box"
          elevation="2">
          <h2 class="content">
            {{ contents.totalOrder | showNumberFormat }}
          </h2>
          <h3 class="title">
            Purchase
          </h3>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      justify="center"
      class="stats-table-row my-4">
      <v-col cols="10">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">
                  Visits
                </th>
                <th class="text-center">
                  Cart
                </th>
                <th class="text-center">
                  Checkout
                </th>
                <th class="text-center">
                  Purchase
                </th>
                <th class="text-center">
                  ยอดขาย
                </th>
                <th class="text-center">
                  Conversion rate
                </th>
                <th class="text-center">
                  ยอดเฉลี่ยต่อออร์เดอร์
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">
                  {{ contents.visitor || 0 | showNumberFormat }}
                </td>
                <td class="text-center">
                  {{ contents.addToCart | showNumberFormat }}
                </td>
                <td class="text-center">
                  {{ contents.checkout | showNumberFormat }}
                </td>
                <td class="text-center">
                  {{ contents.totalOrder | showNumberFormat }}
                </td>
                <td class="text-center">
                  {{ contents.totalSpent | showNumberFormat }}
                </td>
                <td class="text-center">
                  {{ contents.conversionRate || 0 }} %
                </td>
                <td class="text-center">
                  {{ contents.averageSpentPerOrder | showNumberFormat }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="(field, index) in Object.keys(charts)"
        :key="index"
        cols="8"
        offset="2">
        <line-chart
          :chart-data="charts[field].dataCollection"
          :options="charts[field].chartOptions" />
      </v-col>
      <v-col
        cols="8"
        offset="2">
        <bar-chart
          :chart-data="netOrder.dataCollection"
          :options="netOrder.chartOptions" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import LineChart from './charts/LineChart'
import BarChart from './charts/BarChart'

export default {
  components: {
    LineChart,
    BarChart
  },
  props: {
    stats: {
      type: Array,
      required: true
    },
    todayStats: {
      type: Object,
      required: true
    },
    amountOrderPerDate: {
      type: Array,
      required: true
    },
    eachBranch: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      contents: {
        visitor: 0,
        addToCart: 0,
        purchase: 0,
        checkout: 0,
        totalOrder: 0,
        totalSpent: 0,
        conversionRate: 0,
        averageSpentPerOrder: 0
      },
      todayStat: {
        totalSpent: 0,
        totalOrder: 0
      },
      netOrder: {
        dataCollection: null,
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: 'Net Order Value',
            position: 'top'
          }
        }
      },
      charts: {
        visitor: {
          dataCollection: null,
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: true,
              text: 'Visits',
              position: 'top'
            }
          }
        },
        addToCart: {
          dataCollection: null,
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: true,
              text: 'Cart',
              position: 'top'
            }
          }
        },
        checkout: {
          dataCollection: null,
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: true,
              text: 'Checkout',
              position: 'top'
            }
          }
        },
        purchase: {
          dataCollection: null,
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: true,
              text: 'Purchase',
              position: 'top'
            }
          }
        },
        spentNumber: {
          dataCollection: null,
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: true,
              text: 'ยอดขายเว็บไซต์',
              position: 'top'
            }
          }
        },
        conversionRate: {
          dataCollection: null,
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: true,
              text: 'Conversion rate',
              position: 'top'
            }
          }
        },
        cartAbandontmentRate: {
          dataCollection: null,
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: true,
              text: 'Cart abandontment rate',
              position: 'top'
            }
          }
        }
      }
    }
  },
  watch: {
    todayStats: {
      handler () {
        this.initTodayStats()
      },
      deep: true
    },
    stats: {
      handler () {
        this.fillData()
        this.initLabel()
        this.getReports()
      },
      deep: true
    },
    amountOrderPerDate: {
      handler () {
        this.fillData()
        this.initLabel()
        this.getReports()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    async getReports () {
      try {
        this.loaded = false

        this.initEachDateChart()
        this.initReportTable()
        this.initNetOrder()
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
        console.error('GET WEB REPORTS ERROR')
      } finally {
        this.loaded = true
      }
    },
    async initEachDateChart () {
      Object.keys(this.charts).forEach((chart) => {
        this.charts[chart].dataCollection.datasets = this.prepareDataForDataSets(this.stats, chart)
      })
    },
    async initTodayStats () {
      this.todayStat.totalSpent = this.todayStats?.totalSpent || 0
      this.todayStat.totalOrder = this.todayStats?.totalOrder || 0
    },
    fillData () {
      Object.keys(this.charts).forEach((chart) => {
        this.charts[chart].dataCollection = {
          labels: [],
          datasets: []
        }
      })

      this.netOrder.dataCollection = {
        labels: [],
        datasets: []
      }
    },
    reportFinder (reports, chart) {
      const results = []

      // Days
      reports.forEach((report) => {
        let initialized = {
          addToCart: 0,
          checkout: 0,
          purchase: 0,
          visitor: report.visitor || 0,
          cartAbandontmentRate: report.cartAbandontmentRate || 0,
          spentNumber: 0,
          conversionRate: 0
        }

        // Reports in single day
        report.reports.forEach((data) => {
          const purchase = data?.orderNumber || 0
          initialized = {
            addToCart: initialized.addToCart + report.addToCart,
            checkout: initialized.checkout + report.checkout,
            purchase: initialized.purchase + purchase,
            visitor: initialized.visitor,
            cartAbandontmentRate: initialized.cartAbandontmentRate,
            spentNumber: initialized.spentNumber + data?.spentNumber || 0,
            conversionRate: ((initialized.purchase + purchase) / (initialized.visitor + report.visitor)) * 100 || 0
          }
        })

        const temp = initialized[chart] || 0
        results.push(temp.toFixed(2))
      })

      return results
    },
    prepareDataForDataSets (reports, chart) {
      const result = []

      result.push({
        label: 'WEB',
        fill: false,
        borderColor: 'green',
        backgroundColor: 'green',
        data: this.reportFinder(reports, chart)
      })

      return result
    },
    initLabel () {
      const label = this.stats.map((stat) => stat.reportDate)

      Object.keys(this.charts).forEach((chart) => {
        this.charts[chart].dataCollection.labels = label
      })
    },
    async initNetOrder () {
      this.netOrder.dataCollection.labels = this.amountOrderPerDate.map((each) => each.label)
      this.netOrder.dataCollection.datasets = [{
        label: 'Order',
        data: this.amountOrderPerDate.map((each) => each.amount),
        backgroundColor: 'green',
        borderColor: 'green'
      }]
    },
    initReportTable () {
      const contents = []
      this.eachBranch.forEach((report) => {
        contents.push({
          ...report,
          label: 'Value',
          conversionRate: ((report.purchase / report.visitor) * 100).toFixed(2)
        })
      })

      if (contents.length > 0) {
        this.contents = contents[0]
      }
    }
  }
}
</script>

<style scoped>
.number-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
  border: 2px solid black;
  position: relative;
}

.number-box .title {
  position: absolute;
  top: -17px;
  left: 5px;
  padding: 0 5px;
  background-color: #fff;
}

.number-box .extend {
  position: absolute;
  bottom: 0px;
  right: 2px;
}

.number-row {
  margin: 16px auto;
}

.stats-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 11%;
  border: 2px solid black;
  position: relative;
}

.stats-box .title {
  position: absolute;
  padding: 0 5px;
  bottom: -15px;
  font-size: 17px !important;
  background-color: #fff;
}

.stats-connector {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 11%;
  position: relative;
}

.stats-connector .text {
  position: absolute;
  bottom: 0px;
}
</style>
