<template>
  <v-container>
    <v-row justify="center">
      <div class="table-wrap ma-2">
        <v-data-table
          :headers="headers"
          :items="contents"
          hide-default-footer
          dense
          :items-per-page="20"
          class="elevation-1">
          <template v-slot:[`item.label`]="{ item }">
            {{ item.label.toUpperCase() }}
          </template>
          <template v-slot:[`item.visitor`]="{ item }">
            {{ item.visitor }}
          </template>
          <template v-slot:[`item.totalOrder`]="{ item }">
            {{ showFullPriceFormat(item.totalOrder, 0) }}
          </template>
          <template v-slot:[`item.totalSpent`]="{ item }">
            {{ showFullPriceFormat(item.totalSpent, 0) }}
          </template>
          <template v-slot:[`item.conversionRate`]="{ item }">
            {{ `${item.conversionRate.toFixed(2)}%` }}
          </template>
          <template v-slot:[`item.averageSpentPerOrder`]="{ item }">
            {{ showFullPriceFormat(item.averageSpentPerOrder, 0) }}
          </template>
          <template v-slot:[`body.append`]>
            <tr>
              <td>{{ totalInReportTable.label }}</td>
              <td class="text-center">
                {{ showFullPriceFormat(totalInReportTable.visitor, 0) }}
              </td>
              <td class="text-center">
                {{ showFullPriceFormat(totalInReportTable.totalOrder, 0) }}
              </td>
              <td class="text-center">
                {{ showFullPriceFormat(totalInReportTable.totalSpent, 0) }}
              </td>
              <td class="text-center">
                {{ `${showFullPriceFormat(totalInReportTable.conversionRate, 2)}%` }}
              </td>
              <td class="text-center">
                {{ showFullPriceFormat(totalInReportTable.averageSpentPerOrder, 0) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-row>
    <v-row>
      <v-col
        v-for="(field, index) in Object.keys(charts)"
        :key="index"
        cols="8"
        offset="2">
        <line-chart
          :chart-data="charts[field].dataCollection"
          :options="charts[field].chartOptions" />
      </v-col>
      <v-col
        cols="8"
        offset="2">
        <bar-chart
          :chart-data="amountOrderOfSpentRange.dataCollection"
          :options="amountOrderOfSpentRange.chartOptions" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import availableBranches from '@/assets/json/AvailableBranches.json'
import LineChart from './charts/LineChart'
import BarChart from './charts/BarChart'

export default {
  components: {
    LineChart,
    BarChart
  },
  props: {
    stats: {
      type: Array,
      required: true
    },
    eachBranch: {
      type: Array,
      required: true
    },
    amountOrderPerDate: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      loaded: true,
      amountOrderOfSpentRange: {
        dataCollection: null,
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: 'Net Order Value',
            position: 'top'
          }
        }
      },
      charts: {
        visitor: {
          dataCollection: {},
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: true,
              text: 'จำนวนคนเข้าร้าน',
              position: 'top'
            }
          }
        },
        orderNumber: {
          dataCollection: null,
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: true,
              text: 'จำนวนออเดอร์',
              position: 'top'
            }
          }
        },
        spentNumber: {
          dataCollection: null,
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: true,
              text: 'ยอดขาย',
              position: 'top'
            }
          }
        },
        conversionRate: {
          dataCollection: null,
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: true,
              text: 'Conversion Rate',
              position: 'top'
            }
          }
        }
      },
      headers: [
        {
          text: '',
          sortable: false,
          value: 'label'
        },
        {
          text: 'คนเข้าร้าน',
          sortable: true,
          align: 'center',
          value: 'visitor'
        },
        {
          text: 'จำนวนออเดอร์',
          sortable: true,
          align: 'center',
          value: 'totalOrder'
        },
        {
          text: 'ยอดขาย',
          sortable: true,
          align: 'center',
          value: 'totalSpent'
        },
        {
          text: 'Conversion rate',
          sortable: true,
          align: 'center',
          value: 'conversionRate'
        },
        {
          text: 'ยอดเฉลี่ยต่อออเดอร์',
          sortable: true,
          align: 'center',
          value: 'averageSpentPerOrder'
        }
      ],
      contents: [],
      totalInReportTable: {
        label: 'TOTAL',
        visitor: 0,
        totalOrder: 0,
        totalSpent: 0,
        conversionRate: 0,
        averageSpentPerOrder: 0
      }
    }
  },
  watch: {
    eachBranch: {
      handler () {
        this.fillData()
        this.initLabel()
        this.getReports()
      }
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    async getReports () {
      try {
        this.loaded = false
        this.initEachDateChart()
        this.initReportTable()
        this.initAmountOrderOfSpentRange()
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
        console.error('GET POS REPORTS ERROR')
      } finally {
        this.loaded = true
      }
    },
    async initEachDateChart () {
      Object.keys(this.charts).forEach((chart) => {
        this.charts[chart].dataCollection.datasets = this.prepareDataForDataSets(this.stats, chart)
      })
    },
    reportFinder (reports, branch, chart) {
      const results = []
      reports.forEach((report) => {
        const found = report.reports.find((dataSet) => dataSet.branch.id === branch.id)
        if (found) {
          const temp = found[chart] || 0
          results.push(temp.toFixed(2))
        } else {
          results.push(0)
        }
      })

      return results
    },
    prepareDataForDataSets (reports, chart) {
      const branches = this.eachBranch.map((branch) => branch.branch)
      const result = []

      branches.forEach((branch) => {
        const branchColors = availableBranches.find((each) => each.id === branch.id)
        if (branchColors) {
          result.push({
            label: branch.name.toUpperCase(),
            fill: false,
            borderColor: branchColors.color || 'grey',
            backgroundColor: branchColors.color || 'grey',
            data: this.reportFinder(reports, branch, chart)
          })
        }
      })

      return result
    },
    initReportTable () {
      const contents = []
      this.eachBranch.forEach((report) => {
        const available = availableBranches.find((avBranches) => avBranches.id === report.branch.id)
        if (available) {
          contents.push({
            ...report,
            label: available.label,
            conversionRate: (report.visitor !== 0) ? (report.totalOrder * 100) / report.visitor : 0
          })
        }
      })

      this.totalInReportTable = this.calcTotalOrderStats(contents)
      // contents.push(this.totalInReportTable)
      this.contents = contents
    },
    async initAmountOrderOfSpentRange () {
      this.amountOrderOfSpentRange.dataCollection.labels = this.amountOrderPerDate.map((each) => each.label)
      this.amountOrderOfSpentRange.dataCollection.datasets = [{
        label: 'Bills',
        data: this.amountOrderPerDate.map((each) => each.amount),
        backgroundColor: '#446bc7',
        borderColor: '#446bc7'
      }]
    },
    calcTotalOrderStats (contents) {
      const totalVisitor = contents.reduce((sum, content) => sum + content.visitor, 0)
      const totalOrderNumber = contents.reduce((sum, content) => sum + content.totalOrder, 0)
      const totalSpentNumber = contents.reduce((sum, content) => sum + content.totalSpent, 0)
      const totalConversionRate = (totalVisitor !== 0) ? (totalOrderNumber * 100) / totalVisitor : 0
      const totalAverageSpentPerOrder = totalSpentNumber / totalOrderNumber

      return {
        label: 'TOTAL',
        visitor: totalVisitor,
        totalOrder: totalOrderNumber,
        totalSpent: totalSpentNumber,
        conversionRate: totalConversionRate,
        averageSpentPerOrder: totalAverageSpentPerOrder
      }
    },
    fillData () {
      Object.keys(this.charts).forEach((chart) => {
        this.charts[chart].dataCollection = {
          labels: [],
          datasets: []
        }
      })

      this.amountOrderOfSpentRange.dataCollection = {
        labels: [],
        datasets: []
      }
    },
    initLabel () {
      const label = this.stats.map((stat) => stat.reportDate)

      Object.keys(this.charts).forEach((chart) => {
        this.charts[chart].dataCollection.labels = label
      })
    },
    showFullPriceFormat (number = 0, fixed = 2) {
      return parseFloat(number).toFixed(fixed).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }
}
</script>

<style scoped>
.page-title {
  text-decoration: underline;
}
.table-wrap {
  width: 90%;
}
</style>
